import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { mapKeys, isEmpty, omit, isPlainObject, mapValues } from 'lodash'
import { createComponent, updateComponentKeys, removeComponents, duplicateComponent } from '../components/componentSlice'
import { uniqueComponentKey, reverseUniqueComponentKey } from '../../common/utility'

export const CONTROLLED_FIELDS = ['id', 'position', 'positions', 'label', 'placeholder']

const initialState = {
  data: {},
  signature: {}
}

function addField(state, { key, component, template }) {
  if (component.type !== 'data')
    return

  state.data[component.id] = { id: component.id, position: key, ...template }
}

function addSignature(state, { key, component, template }) {
  if (component.type !== 'signature' || !component.subtype)
    return

  state.signature[component.id] ||= {
    id: component.id,
    positions: {},
    ...template
  }

  state.signature[component.id].positions[component.subtype] = key
}

function removeFromSignature(state, id, subtype) {
  if (!state.signature[id]) return

  if (subtype !== 'signature'){
    delete state.signature[id].positions[subtype]
  } else {
    delete state.signature[id]
  }
}

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {

    updateTemplate(state, { payload: { type, id, template}}) {
      if (!state[type][id]) return

      state[type][id] = { ...state[type][id], ...template }
    },

    updateTemplates(state, { payload: { type, templates }}) {
      state[type] ||= {}
      state[type] = { ...state[type], ...templates }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createComponent, (state, { payload: { key, component }}) => {
      addField(state, { key, component })
      addSignature(state, { key, component })
    })

    builder.addCase(duplicateComponent, (state, { payload: { key, component, duplicateComponent }}) => {
      const template = state[component.type][component.id]
      addField(state, { key, component: duplicateComponent, template })
      addSignature(state, { key, component: duplicateComponent, template })
    })

    builder.addCase(updateComponentKeys, (state, { payload: { deltaIds, type }}) => {
      state[type] ||= {}

      let temp, newId
      for (let oldId in deltaIds) {
        newId = deltaIds[oldId]
        temp = state[type][oldId]
        delete state[type][oldId]

        if (isPlainObject(temp.positions))
          state[type][newId] = { ...temp, id: newId, positions: mapValues(temp.positions, (_, subtype) => uniqueComponentKey(type, newId, subtype))  }
        if (temp.position)
          state[type][newId] = { ...temp, id: newId, position: uniqueComponentKey(type, newId) }
      }
    })

    builder.addCase(removeComponents, (state, {payload: keys}) => {
      keys.forEach(key => {
        const { type, id, subtype } = reverseUniqueComponentKey(key)

        switch(type) {
        case 'data':
          delete state.data[id]
          return
        case 'signature':
          removeFromSignature(state, id, subtype)
          return
        }
      })
    })
  }
})

export function useDataTemplate(type, id) {
  const data = useSelector(state => state.data[type][id])
  return omit(data, CONTROLLED_FIELDS)
}

export const { updateTemplate, updateTemplates } = dataSlice.actions
export default dataSlice.reducer
