import React from 'react'
import { asArray } from 'lib/utilities'

const InputItems = ({items}) => {
  items = asArray(items)
  if (items.length == 0)
    return null

  return <div className="pended-item-container">{items}</div>
}

const Input = (props) => {
  const { id, name, label, classes, placeholder, onKeyDown, onFocus, onBlur, onChange, value, prepend, append, type='text'} = props

  return (
    <div className={`input-label-group ${classes ?? ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="inputs input-rounded">
        <InputItems items={prepend} />
        <input id={id} name={name} type={type} value={value} onChange={onChange} onFocus={onFocus} onKeyDown={onKeyDown} onBlur={onBlur} placeholder={placeholder} />
        <InputItems items={append} />
      </div>
    </div>
  )
}

export default Input
//
