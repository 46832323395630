import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import MiniSearch from 'minisearch'
import { compact, intersection, isEmpty, last } from 'lodash'
import Field from 'components/form_field'
import { addPath, clearValues, removePath, updateValues } from './state'
import { metadataByPath } from 'lib/utilities/form'
import { useClasses } from 'lib/utilities/react'
import { storePathValues } from './api'

function metadataToPaths(metadata, { keys = [], labels = [], ids = [] } = {}) {
  const values = []
  for (let key in metadata) {
    const field = metadata[key]
    const compiled = {
      keys: [ ...keys, key],
      labels: [ ...labels, field.label ],
      ids: [ ...ids, field.id ]
    }
    
    if (metadata[key].type == "object")
      values.push(metadataToPaths(metadata[key].fields, compiled))
    else
      values.push({ ...compiled, field })    
  }

  return values.flat()
}

function Fields() {
  const building = useSelector(state => state.building)
  const metadata = useSelector(state => state.metadata)

  if (!building || !metadata)
    return

  return <div className="fields">
    <CurrentAddress />
    <FieldSearch />
    <FieldList />
    <div className="buttons">
      <ClearButton />
      <SaveButton />
    </div>
  </div>
}

function CurrentAddress() {
  const building = useSelector(state => state.current_building)
  const structure = useSelector(state => state.current_structure)
  const unit = useSelector(state => state.current_unit)

  const renderedStructure = useMemo(() => {
    if (!structure) return null

    return <div className="structure">
      <div>structure</div>
      <div>
        <span className="structure-name">{ structure.name }</span>
      </div>
    </div>
  }, [structure])

  const renderedUnit = useMemo(() => {
    return <div className="unit">
      <div>unit</div>
      <div>{unit || "No Unit Specified"}</div>
    </div>
  }, [unit])


  if (!building)
    return null

  return <div className="current-address">
    <div className="building">
      <div>building</div>
      <div>{building.title}</div>
    </div>
    { renderedStructure }
    { renderedUnit }
  </div>
}

function FieldSearch() {
  const [query, setQuery] = useState("")
  const [value, setValue] = useState("")

  useEffect(() => {
    const timeout = setTimeout(() => setQuery(value), 400)
    return () => clearTimeout(timeout)
  }, [value, setQuery])

  return <div className="field-search">
    <div className="bar">
      <i className="fa fa-search" />
      <input type="input" value={value} onChange={evt => setValue(evt.target.value)} placeholder="Search for Fields / Paths" />
    </div>
    <FieldSearchOptions query={query} onSelect={() => setValue('')} />
  </div>
}

function useDocSearch() {
  const metadata = useSelector(state => state.metadata)

  const documents = useMemo(() => {
    return metadataToPaths(metadata).map(({field, keys, ids, labels}) =>({
      field,
      labels,
      path: keys.join("."),
      id: ids.join(" - "),
      text: labels.join(" ")
    }))
  }, [metadata])

  return useMemo(() => {
    const search = new MiniSearch({
      fields: ['path', 'id', 'text'],
      storeFields: ['field', 'id', 'path', 'labels']
    })

    search.addAll(documents)
    return search
  }, [documents])
}

function useSearchFilter() {
  const building_id = useSelector(state => state.current_building?.id)
  const structure_id = useSelector(state => state.current_structure?.id)
  const unit = useSelector(state => state.current_unit)

  const association = useMemo(() => compact([
    building_id ? "building" : null,
    structure_id ? "structure" : null,
    unit ? "unit" : null
  ]), [building_id, structure_id, unit])

  return useCallback(result => {
    return intersection(result?.field?.association || [], association).length > 0
  }, [association])
}

function FieldSearchOptions({ query, onSelect }) {
  const dispatch = useDispatch()
  const search = useDocSearch()
  const filter = useSearchFilter()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen( !isEmpty(query) )
  }, [query, setOpen])

  const handleClick = useCallback(path => {
    dispatch(addPath(path))
    setOpen(false)
    onSelect()
  }, [dispatch, setOpen, onSelect])

  const results = useMemo(() => search.search(query, {
    filter,
    prefix: true,
    fuzzy: 0.2,
    boost: { path: 2 }
  }).slice(0, 10), [search, query, filter])

  if (!open)
    return null

  return <div className="dropdown">
    { results.map(result => <FieldSearchResult key={result.path} {...result} onClick={() => handleClick(result.path) } /> )}
  </div>
}

function FieldSearchResult({ field, path, labels, onClick }) {
  return <div className="result" onClick={onClick}>
    <div className="title">{labels.join(" -> ")}</div>
    <div>{path}</div>
  </div>
}

function FieldList() {
  const dispatch = useDispatch()
  const values = useSelector(state => state.values)

  const updateValue = useCallback(hash => {
    dispatch(updateValues(hash))
  }, [dispatch])

  const fields = useMemo(() => {
    return Object.keys(values).map(path => <SinglePath key={path} path={path} value={values[path]} onChange={updateValue} />)
  }, [values, updateValue])

  const noFields = useMemo(() => {
    return <div className="no-fields">Search and select some fields to edit.</div>
  }, [])

  return <div className="field-list">
    { isEmpty(values) ? noFields : fields }
  </div>
}

function SinglePath({path, value, onChange}) {
  const dispatch = useDispatch()
  const metadata = useSelector(state => state.metadata)
  const field = metadataByPath(metadata, path)

  const prefix = useMemo(() => {
    const currentPath = []
    return compact(path.split('.').slice(0, -1).map(section => {
      currentPath.push(section)
      return metadataByPath(metadata, currentPath.join('.'))?.label
    })).join(' -> ')
  }, [metadata, path])

  const handleRemove = useCallback(() => {
    dispatch(removePath(path))
  }, [dispatch, path])

  return <div>
    <div className="title">
      <div className="prefix">{prefix}</div>
      <div className="field-label">{field.label}</div>
      <div className="path">{path}</div>
    </div>
    <div className="field">
      <Field field={field} onChange={onChange} id={path} skipLabel={true} value={value} />
    </div>
    <div className="remove">
      <button onClick={handleRemove} className="btn btn-sm">remove</button>
    </div>
  </div>
}

function SaveButton() {
  const building_id = useSelector(state => state.current_building.id)
  const structure_id = useSelector(state => state.current_structure?.id)
  const unit = useSelector(state => state.current_unit)
  const values = useSelector(state => state.values)

  const disabled = !building_id || isEmpty(values)

  const classes = useClasses([
    'btn',
    'btn-primary',
    disabled ? "disabled" : null
  ])

  const handleClick = useCallback(() => {
    storePathValues(values, { building_id, structure_id, unit })
  }, [building_id, structure_id, unit, values])

  return <button className={classes} disabled={disabled} onClick={handleClick}>Store Values</button>
}

function ClearButton() {
  const dispatch = useDispatch()

  const classes = useClasses([
    'btn', 'btn-default'
  ])

  const handleClick = useCallback(() => {
    dispatch(clearValues())
  }, [dispatch])

  return <button className={classes} onClick={handleClick}>Clear</button>
}

export default Fields