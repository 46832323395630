import clsx from 'clsx'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

export const DependentFieldModal = ({ signatureId }) => {
  const fullName = useSelector((state) => state.data.signature[signatureId]?.positions?.name)
  const dateSigned = useSelector((state) => state.data.signature[signatureId]?.positions?.time)

  return (
    <div
      style={{
        position: 'absolute',
        right: '-245px',
        top: '-61px',
        backgroundColor: 'white',
        border: '1px solid #F0F0F0',
        borderRadius: '6px',
        padding: '18px 12px',
        boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.16)',
      }}
    >
      <div className="dome-d-flex dome-gap12 dome-flex-column">
        <div className="dome-p2-lt">add any dependent field:</div>
        <div className="dome-d-flex dome-gap6 dome-p2">
          <button
            disabled
            type="button"
            className={clsx('dome-btn dome-btn-base dome-btn-grey-stroke', dateSigned && 'dome-btn-disabled')}
          >
            date signed
          </button>
          <button
            disabled
            type="button"
            className={clsx('dome-btn dome-btn-base dome-btn-grey-stroke', fullName && 'dome-btn-disabled')}
          >
            full name
          </button>
        </div>
      </div>
    </div>
  )
}
