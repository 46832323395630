import React, { useCallback, useEffect, useState } from 'react'
import { chooseMaxPixels, convertToPerc, isSameRect, isValidInput } from '../utility'

const useDeltaPosition = (position, pageWidth, pageHeight) => {
  const [deltaPosition, setDeltaPosition] = useState(position)

  useEffect(() => {
    if (isSameRect(position, deltaPosition)) return

    setDeltaPosition(position)
  }, [position, deltaPosition])

  const updateDeltaPosition = useCallback(
    (dimension, pixels) => {
      if (!isValidInput(pixels)) return

      const value = convertToPerc(
        pixels,
        chooseMaxPixels(dimension, pageWidth, pageHeight)
      )

      setDeltaPosition({ ...deltaPosition, [dimension]: value })
    },
    [deltaPosition, pageWidth, pageHeight]
  )

  return [deltaPosition, updateDeltaPosition]
}

export default useDeltaPosition
